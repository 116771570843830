
import {
  Layers,
  Clipboard,
  User,
  Users,
  Edit,
  BookOpen,
  Calendar,
  Briefcase,
  Heart,
  Printer,
} from "react-feather";

import {
  EuroOutlined,
  DirectionsCarFilledOutlined,
  PersonOffOutlined

} from "@mui/icons-material";

import {IsBevoegd} from "../auth/RequireRecht";
import {bConst} from "../../constants";

//******************
// Testpages
//****************** 
const testSection = [
  {
    href: "/serverReadTest",
    icon: Clipboard,
    title: "Server Readtest",
  },
  {
    href: "/serverWriteTest",
    icon: Clipboard,
    title: "Server Writetest",
  },  
  // {
  //   href: "/login",
  //   icon: User,
  //   title: "Login",
  // },
  // {
  //   // href: "/logout",
  //   icon: User,
  //   title: "Logout",
  // },
  {
    href: "/testclients",
    icon: Clipboard,
    title: "Test Clients",
  },  
  
  {
    href: "/testpage",
    icon: Clipboard,
    title: "Test Page",
  },
];

//******************
// Medewerkers
//****************** 
const clientSection = [
  {
    href: "/clients",
    icon: Users,
    title: "Clientenoverzicht",
  },
];

const declSection = [
  {
    href: "/km_declaratie",
    icon: DirectionsCarFilledOutlined,
    title: "Kilometer-declaratie",
  }, 
  {
    href: "/kosten_declaratie",
    icon: EuroOutlined,
    title: "Kosten-declaratie",
  },    
];

const userMedwSection = [ 
  {
    href: "/logout",
    icon: PersonOffOutlined,
    title: "Afmelden",
  },  
];

const navItemClients =
{
  title: "Clienten",
  pages: clientSection,
};

const navItemDecl = 
{
  title: "Declaraties",
  pages: declSection,
};

const navItemMedwUser = 
{
  title: "Gebruiker",
  pages: userMedwSection,
};


//******************
// Clientportaal 
//****************** 
const cp_PortaalSection = [
  {
    href: "/clients",
    icon: Users,
    title: "Cliëntdossier(s)",
  },
];

const cp_AfsprakenSection = [
  {
    href: "/portaal_afspraken",
    icon: Calendar,
    title: "Afspraken",
  },
];

const cp_TakenSection = [
  {
    href: "/portaal_taken",
    icon: Clipboard,
    title: "Taken",
  },
];

const cp_DocSection = [  
  {
    href: "/portaal_documenten",
    icon: BookOpen,
    title: "Documenten",
  }, 

];

const userPortaalSection = [
  {
    href: "/wijzig_password",
    icon: Edit,
    title: "Wachtwoord",
  },

  {
    href: "/logout",
    icon: PersonOffOutlined,
    title: "Afmelden",
  },  
];

const navItemCpClients =
{
  title: "Cliënten",
  pages: [], 
};

const navItemPortaal =
{
  title: "Dossier",
  pages: [], 
};
  
const navItemPortaalUser = 
{
  title: "Gebruiker",
  pages: userPortaalSection,
};
  

//******************
// Configer sidebar
//****************** 
export let navItems = [];

export function configerSidebar (isMedw, medwDecl) {
  navItems = []
  if (isMedw===1) {
    navItems.push(navItemClients) 
    if (medwDecl===1) { navItems.push(navItemDecl) } 
    navItems.push(navItemMedwUser)
  } else { 
    sidebarCp()
  }  
}

export function sidebarCp() {
  navItemCpClients.pages=[]
  navItemCpClients.pages.push(cp_PortaalSection[0])

  navItems = []
  navItems.push(navItemCpClients) 
  navItems.push(navItemPortaalUser)     
}

export function sidebarCpOne() {
  navItemCpClients.pages=[]
  navItemCpClients.pages.push(cp_PortaalSection[0])

  navItems = []
  navItems.push(navItemPortaalUser)     
}



