import React, {useEffect} from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Paper } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/bloqslogo.svg";
import Loginform from "./Loginform";
import TfaCodeform from "./TfaCodeform";
import TfaQRform from "./TfaQRform";
import NewPWform from "./NewPWform";
import AuthContext from "../../contexts/AuthContext";
import { bConst } from "../../constants";

// const AuthContext = React.createContext(null);
const Brand = styled(Logo)`
  height: 60px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: ${(props) => props.theme.spacing(10)};
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.23), 0 2px 2px rgba(0, 0, 0, 0.23);
  }
`;

export function Login(props) {
 const { user, scanQR, QR, tfaVerified, newPwNeeded, setVerified } = React.useContext(AuthContext);

  // const setTokenVerificatie = () => {    
  //   // if (user) {setVerified()} 
  // }
  return (
    <React.Fragment>
      <Wrapper>
        <Brand />
        <Helmet title="Inloggen" />        

        { ( !user)  //!accesstoken || !refreshtoken || !user) 
          ? <Loginform />
          : (!tfaVerified)
            ? (!QR || scanQR)
              ? <TfaQRform />
              : <TfaCodeform />
            : (newPwNeeded)
              ? <NewPWform withPW={false} />
              : <Loginform />
        }    

      </Wrapper>
    </React.Fragment>
  );
}

export default Login;
