import React from "react";
import AuthContext from "../../contexts/AuthContext";
import * as speakeasy from 'speakeasy'; 
import * as qrcode from 'qrcode';
import { copyToClipboard } from "../../utils/ComProc";
import { MessDialogYN } from "../messages/MessDialog";

// import { Button as MuiButton } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import {
  Typography,
  Grid,
  Button,
  IconButton,
  TextField as MuiTextField,
} from "@mui/material";

import {
  FilterNoneOutlined, 
  Check,
  Close,
} from "@mui/icons-material";

// const IconButton = styled(MuiButton);
const TextField = styled(MuiTextField)(spacing);

export function ScanQR() {
  const { logout, verifyTfa, saveTfa, setScanQR, user, settings} = React.useContext(AuthContext);

  const [tfaSecret, setTfaSecret] = React.useState(undefined);
  const [qrCodeUri, setQrCodeUri] = React.useState(undefined);
  const [tfaToken, setTfaToken] = React.useState('');
  // const [openDialog, setOpenDialog] = React.useState(undefined);
  // const [isSubmitting, setSubmitting ] = React.useState(false);

  const logoutUser = async () => {
    await logout();
  }   
 
  const onCloseQR = async () => {
    setScanQR(true)
    copyToClipboard(' ')
    logoutUser() 
  }  

  let accountName='bloqs';
  if (user.isMedw===1) { accountName='bloqs Cliëntvolgsysteem' } else { accountName=settings.BdrNaam+'-Cliëntportaal' } 
  
  const generateSecret = async() => {
     return speakeasy.generateSecret({ length: 20 });
  }

  const CheckQRCode = async (event) => {
    event.preventDefault();
    // console.log('Token: '+tfaToken)
    copyToClipboard(' ')
    const saved = await saveTfa(tfaSecret.base32)
    if (saved) {
      const verified=await verifyTfa(tfaToken);
      if (verified) {
        setScanQR(false);
      } else {  
        await saveTfa('');  // maak de TFA in de database leeg.
      }
    } else {
      alert('Er is een fout opgetreden bij het opslaan van de QR-code in bloqs.') 
    }
    setTfaToken("");
  } 

  const genTfa = async() => {
    setScanQR(true);
    const newSecret =  await generateSecret();
    setTfaSecret(newSecret);
    // console.log('Secret:  '+newSecret.base32)
    const qrCodeUrl = `otpauth://totp/${accountName}?secret=${newSecret.base32}`;
    const newQrCodeUri = await qrcode.toDataURL(qrCodeUrl);
    setQrCodeUri(newQrCodeUri);
  };

  // const save_tfa = async(confirm) => {
  //   if (confirm) {
  //     const saved =  await saveTfa(tfaSecret.base32)
  //     if (saved) {setScanQR(false)} else { logoutUser() }
  //   } 
  // }
  
  const save_code = async() => {
    copyToClipboard(tfaSecret.base32)
  }

  const genQR = () => {
   if (!tfaSecret) { genTfa() }
  }

  return (
    <React.Fragment>
      { genQR() }
 
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        QR-code voor de Authenticator-App 
      </Typography>

      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {qrCodeUri && <img src={qrCodeUri}  alt="QR code voor Authenticator-App" /> }
        <Button sx={{pl: 3}} type="button" variant="contained" disabled={!tfaSecret} startIcon={<FilterNoneOutlined />} onClick={save_code}> 
              Kopie
        </Button> 
        {/* <IconButton size="small" onClick={save_code}> <FilterNoneOutlined fontSize="small" /> -Kopie</IconButton> */}
      </Grid>

      <Typography component="h5" variant="h8" align="center" gutterBottom>
        QR-code scannen met de App
      </Typography>
      <Typography component="h5" variant="h8" align="center" gutterBottom>
        of een kopie maken en plakken in de App.
      </Typography>
      <Typography component="h5" variant="h8" align="center" gutterBottom>
        De code uit de App invullen en daarna Accepteren drukken.
      </Typography>
      <Grid container mt={0} spacing={1} justifyContent="center">

        <Grid item xs={10} mt={0} justifyContent="center" alignItems="center">
          <TextField mt={0} ml={10} 
            type="text"
            label="Authenticator-code"
            autoFocus
            value={tfaToken}
            required
            sx={{ width: '180px' }}
            onChange={(e) => setTfaToken(e.target.value)}
            my={3}
          />
        </Grid>

        <Grid container xs={5}  spacing={1} justifyContent="center">
            <Button sx={{pl: 3}} type="button" variant="contained" disabled={!tfaSecret} startIcon={<Check />} onClick={CheckQRCode}> 
              Accepteren
            </Button> 
        </Grid>

        <Grid container xs={5} spacing={1} justifyContent="center">
            <Button type="button" variant="contained" startIcon={<Close />} onClick={onCloseQR} > 
              Annuleren
            </Button> 
        </Grid>

      </Grid>

    </React.Fragment>      
  )
}

export default ScanQR;
