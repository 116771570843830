import axios from "axios";

// DEZE LATEN BESTAAN
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
 });
 
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || { status: '99', message: 'De verbinding met de server is verbroken' } //"De verbinding met de server is verbroken"
//     )
// );



export default axiosInstance;
