import React, { useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";

function Logout() {
  const {logout, user} = React.useContext(AuthContext);

  useEffect(()=>{
    if (user) {
      logout()
    }
  }, [logout, user])

  return (null)

  // return (
  //   <React.Fragment>
  //     <Wrapper>
  //       <Brand />
  //       <Helmet title="Logout" />        
  //       <Typography component="h2" variant="h3" align="center" gutterBottom>
  //         U bent afgemeld !!! 
  //       </Typography>
  //       <br/>

  //       <Button
  //         variant="contained"
  //         color="primary"
  //         fullWidth
  //         onClick={() => navigate("/")}
  //       >
  //         Opnieuw aanmelden
  //       </Button>

  //     </Wrapper>
  //   </React.Fragment>
  // );
  
}

export default Logout;
