import React, { useEffect } from "react";
import styled from "styled-components/macro"; 
import { spacing } from "@mui/system";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  DeleteForever,
  Check
} from "@mui/icons-material";

import {
    Button as MuiButton,
    Card as MuiCard,
    TextField as MuiTextField,
    Paper as MuiPaper,
  } from "@mui/material";
  
const Button = styled(MuiButton)(spacing);

export function MessDialog(props) {
  const title = props.title
  const mess = props.mess
  const handleOk = () => { props.confirm(true) }
  const handleNok = () => { props.confirm(false) }

  useEffect( () => {
  }, [])

  return (
    <Dialog 
      mb={6}
      open={true}
      // onClose={handleNok}
    >
      <DialogTitle id="EditDialog">
        { title ? title: ""}  
      </DialogTitle>

      <DialogContent>
          <DialogContentText id="message">
            { mess }
          </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" mt={3} onClick={handleOk} autoFocus startIcon={<Check />} >
           Accepteren
        </Button>

        <Button variant="contained" color="secondary" mt={3} onClick={handleNok} startIcon={<DeleteForever />} >
          Annuleren
        </Button>
      </DialogActions>
    </Dialog>
)
}

export function MessDialogYN(props) {
  const title = props.title
  const mess = props.mess
  const handleOk = () => { props.confirm(true) }
  const handleNok = () => { props.confirm(false) }

  useEffect( () => {
  }, [])

  return (
    <Dialog 
      mb={6}
      open={true}
      // onClose={handleNok}
    >
      <DialogTitle id="EditDialog">
        { title ? title: ""}  
      </DialogTitle>

      <DialogContent>
          <DialogContentText id="message">
            { mess }
          </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" mt={3} onClick={handleOk} autoFocus startIcon={<Check />} >
           Ja
        </Button>

        <Button variant="contained" color="secondary" mt={3} onClick={handleNok} startIcon={<DeleteForever />} >
          Nee
        </Button>
      </DialogActions>
    </Dialog>
)
}

