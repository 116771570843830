import  React  from "react";
import { bConst } from "../constants"; 

export function getKey() {
    return(Math.floor(Date.now() * Math.random()))
}

export function getBR() {
    return(<br key={Math.floor(Date.now() * Math.random())} />)
}

export function getDocDetail(product) {
    switch (product) {
      case bConst.zp_OZL: return(bConst.dd_OZL);
      case bConst.zp_OMD: return(bConst.dd_OMD);
      case bConst.zp_Con: return(bConst.dd_Con);
      case bConst.zp_Job: return(bConst.dd_Job);
      case bConst.zp_Alg: return(bConst.dd_Alg);
      case bConst.zp_UWV: return(bConst.dd_UWV);
      case bConst.zp_UWVJob: return(bConst.dd_UWVJob);
      case bConst.zp_PPT: return(bConst.dd_PPT);
      default: return(bConst.dd_None);
    }
  }
  
export function getDocArea(product) {
    switch (product) {
      case bConst.zp_OZL: return(bConst.da_Zorg);
      case bConst.zp_OMD: return(bConst.da_Zorg);
      case bConst.zp_Con: return(bConst.da_Zorg);
      case bConst.zp_Job: return(bConst.da_Zorg);
      case bConst.zp_Alg: return(bConst.da_Alg);
      case bConst.zp_UWV: return(bConst.da_UWV);
      case bConst.zp_UWVJob: return(bConst.da_UWVJob);
      case bConst.zp_PPT: return(bConst.da_PPT);
      default: return(bConst.da_None);
    }
  }

 export function getFileExt(filename) {  
  // const fileExt = filename.split('.').pop();
  // const ext=filExt.toLowerCase()
  // return ext
  return filename.split('.').pop().toLowerCase()
 } 
 
 export function extToType(filename) {    
  const ext=getFileExt(filename)
  switch (ext)
    {
      case "png":
          return "image/png";
      case "jpg":
      case "jpeg":
          return "image/jpeg";
      case "pdf":
          return "application/pdf";
      case "doc":
      case "dot":
      case "rtf":
          return "application/msword";
      case "docx":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "docm":
      case "dotm":
          return "application/vnd.ms-word.document.macroEnabled.12";
      case "tif":
      case "tiff":
          return "image/tiff";
      case "txt":
      case "xml":
      case "gpx":
            return "text/plain";
      case "xls":
      case ".xlt":
          return "application/vnd.ms-excel";
      case "xlsx":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "xltx":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
      case "xlsm":
          return "application/vnd.ms-excel.sheet.macroEnabled.12";
      case "xltm":
          return "application/vnd.ms-excel.template.macroEnabled.12";
      default:
          return "application/octet-stream";
    }
  }

  export function typedArrayToURL(typedArray, mimeType) {
    return URL.createObjectURL(new Blob([typedArray.buffer],
        {type: mimeType}))
  }

  export function getArrayIndex(value, arr, field) {
    if (arr) {
      for(var i = 0; i < arr.length; i++) {
        if (arr[i][field] === value) {
          return i;
        }
      }
      return -1 //to handle the case where the value doesn't exist
    } else {return -1}
  }

export function copyToClipboard(text) {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
  
    // Append the textarea to the document
    document.body.appendChild(textarea);
  
    // Select the text in the textarea
    textarea.select();
  
    // Execute the "copy" command to save the text to the clipboard
    document.execCommand('copy');
  
    // Remove the temporary textarea from the document
    document.body.removeChild(textarea);
  }
  
