/*
    By bloqs development

*/

import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
 

export const IsBevoegd = (recht) => {
    const { user } = useContext(AuthContext);
    if ( user.isMedw===0 || (user && user.rechten && user.rechten.find(el => el.Rechten_Id === recht)) ) {
        return true
    } else {
        return false
    }
}


export const RequireRecht = (props) => {
   const { recht, unauthorizedComponent } = props;

    // const initialRequest = useRef(true)

    // if(initialRequest && initialRequest.current) {
    //     //do requestAnimationFrame

    //     initialRequest.current = false;
    // }

    return (
        <>        
        { IsBevoegd(recht)
          ?  <>{props.children}</>          // user heeft het recht
          : (<>                             
               { unauthorizedComponent      // user heeft niet het recht
                 ? unauthorizedComponent
                 : null                
               }
            </>)
        }       
    </>
    );
};

export default RequireRecht